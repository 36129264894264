import { proxy } from 'valtio';
import { Address } from 'viem';

export interface TQuest {
    id: number;
    quest_group_id: number;
    status: string;
    state: string;
    position: number;
    score: number;
    chain_id: number;
    video: string;
    video_cover: string;
    image: string;
    action_button: string;
    name: string;
    description: string;
    key: string;
    created_at: string;
    quest_completed_at?: string;
    metadata?: {
        chain_id: number;
        provider: string;
        formula_version: number;
        contract_address: Address;
        streak_size: number;
        v5_formula_params?: any;
        dexyfi_icon?: string;
        dexyfi_points?: number;
        external_link?: string;
        delay_seconds?: number;
    };
}

export interface TQuestGroup {
    id: number;
    name: string;
    description: string;
    total: string;
    type: string;
    position: number;
    quests: TQuest[] | null;
    created_at: string;
    chain_ids: number[];
}

export interface TUserQuestProgress {
    id?: number;
    user_id?: number;
    quest_id: number;
    quest_group_id: number;
    state: string;
    created_at?: string;
}

export interface IDailyVoucherData {
    voucher: any[];
    signed_voucher: string;
    streak_days: number;
    streak_reward: number;
    streak_size: number;
}

export interface IQuestState {
    questsGroups: TQuestGroup[] | null;
    selectedQuestGroup: number;
    questsLoading: boolean;
    questsSuccess: boolean;
    verificationLoading: boolean;
    verificationSuccess: boolean;
    verifyDailyQuest: boolean;
    questsGroupsChainId: number | null;
    dailyVoucherData: IDailyVoucherData | null;
}

const initialState: IQuestState = {
    questsGroupsChainId: null,
    questsGroups: null,
    selectedQuestGroup: -1,
    questsLoading: false,
    questsSuccess: false,
    verificationLoading: false,
    verificationSuccess: false,
    verifyDailyQuest: false,
    dailyVoucherData: null,
};

const state = proxy<IQuestState>(initialState);

const QuestStore = {
    state,

    setQuestsGroups: ({
        data,
        selectedQuestGroup,
        chainId,
    }: {
        data: TQuestGroup[];
        selectedQuestGroup: number;
        chainId: number | null;
    }) => {
        state.questsGroups = data;
        state.selectedQuestGroup = selectedQuestGroup;
        state.questsGroupsChainId = chainId;
    },

    setSelectedQuestGroup: (selectedQuestGroup: number) => {
        state.selectedQuestGroup = selectedQuestGroup;
    },

    setQuests: ({
        data,
        selectedQuestGroup,
        chainId,
    }: {
        data: TQuestGroup[];
        selectedQuestGroup: number;
        chainId: number | null;
    }) => {
        state.questsGroups = data;

        state.selectedQuestGroup = selectedQuestGroup;
        state.questsGroupsChainId = chainId;

        state.questsLoading = false;
        state.questsSuccess = true;
    },

    updateQuest: (data: TUserQuestProgress) => {
        if (!state.questsGroups) return;

        state.questsGroups = state.questsGroups.map(group => ({
            ...group,
            quests:
                group.quests?.map(quest =>
                    quest.id === data.quest_id
                        ? {
                              ...quest,
                              state: data.state,
                              quest_completed_at: data.created_at,
                          }
                        : quest,
                ) ?? null,
        }));

        state.verificationLoading = false;
        state.verificationSuccess = true;
    },

    setVerificationLoading: (loading: boolean) => {
        state.verificationLoading = loading;
    },

    resetVerificationSuccess: () => {
        state.verificationSuccess = false;
    },

    setQuestsLoading: (loading: boolean) => {
        state.questsLoading = loading;
    },

    resetQuestsState: () => {
        if (!state.questsGroups) return;

        state.questsGroups = null;
        (state.selectedQuestGroup = -1), (state.questsLoading = false);
        state.questsSuccess = false;
        state.verificationLoading = false;
        state.verificationSuccess = false;
    },

    setDailyVoucherData: (metadata: IDailyVoucherData) => {
        state.dailyVoucherData = metadata;
    }
};

export default QuestStore;
