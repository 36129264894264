import { createSmartAccountClientWithMiddleware } from '@/helpers/Vault/createSmartAccountClientWithMiddleware';
import AccountStore from '@/store/AccountStore';
import { ConnectedWallet } from '@privy-io/react-auth';
import { ENTRYPOINT_ADDRESS_V06, walletClientToSmartAccountSigner } from 'permissionless';
import { signerToEcdsaKernelSmartAccount } from 'permissionless/accounts';
import { EntryPoint } from 'permissionless/types';
import { type Chain, createPublicClient, createWalletClient, custom, http } from 'viem';
import { viemDebounce } from '@/hooks/Vault/viemDebounce';
import { KERNEL_ADDRESSES } from '@/hooks/Vault/useConnect/smartAccountConstants';

type TUseConnectReturn = [Function];

export const ENTRY_POINT = '0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789';

export function useConnect(): TUseConnectReturn {
    const connect = async (wallets: ConnectedWallet[], activeChain: Chain, smart_wallet_address?: `0x${string}`) => {
        const publicClient = viemDebounce(
            createPublicClient({
                transport: http(activeChain?.rpcUrls?.default?.http[0] || ''),
                chain: activeChain,
            }),
        );

        AccountStore.setPublicClient(publicClient);

        if (!activeChain) {
            return;
        }

        const embeddedPrivyWallet = wallets.find(wallet => wallet.walletClientType === 'privy');

        try {
            if (!embeddedPrivyWallet) {
                return;
            }

            await embeddedPrivyWallet.switchChain(activeChain.id);

            const eip1193provider = await embeddedPrivyWallet.getEthereumProvider();

            const walletClient = createWalletClient({
                account: embeddedPrivyWallet.address as any,
                chain: activeChain, // Replace this with the chain used by your application
                transport: custom(eip1193provider),
            });

            AccountStore.setWalletClient(walletClient);
            const signer = walletClientToSmartAccountSigner(walletClient as any);

            AccountStore.setSigner(signer);

            let smartAccountParams: {
                entryPoint: EntryPoint;
                signer: any;
                factoryAddress: `0x${string}`;
                accountLogicAddress: `0x${string}`;
                ecdsaValidatorAddress: `0x${string}`;
                address?: `0x${string}`;
            } = {
                entryPoint: ENTRYPOINT_ADDRESS_V06,
                signer: signer,
                factoryAddress: KERNEL_ADDRESSES.FACTORY_ADDRESS,
                accountLogicAddress: KERNEL_ADDRESSES.ACCOUNT_LOGIC,
                ecdsaValidatorAddress: KERNEL_ADDRESSES.ECDSA_VALIDATOR,
            };

            if (smart_wallet_address) {
                smartAccountParams = {
                    ...smartAccountParams,
                    address: smart_wallet_address,
                };
            }

            const kernelAccount = await signerToEcdsaKernelSmartAccount(publicClient, {
                ...smartAccountParams,
            });

            AccountStore.setKernelAccount(kernelAccount);

            const smartAccountClient = await createSmartAccountClientWithMiddleware({
                activeChain,
                kernelAccount,
            });

            AccountStore.setAccountIsLoading(false);
            AccountStore.setAccountAddress(kernelAccount.address);
            AccountStore.setSmartAccount(smartAccountClient);
        } catch (error) {
            console.error('Failed to connect:', error);
        }
    };

    return [connect];
}

export default useConnect;
