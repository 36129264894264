import { Web3WalletTypes } from '@walletconnect/web3wallet';
import { JsonRpcResult } from '@walletconnect/jsonrpc-utils';
import { TypedDataDefinition, WalletClient } from 'viem';
import { SmartAccount } from 'viem/account-abstraction';
import { createSuccessResponse } from './response';

const handleEthSignTypedData = async (
    walletClient: WalletClient,
    account: SmartAccount,
    event: Web3WalletTypes.SessionRequest,
): Promise<JsonRpcResult> => {
    const { params, id } = event;
    const requestParamsMessage = params.request.params[1] as string | undefined;

    if (!requestParamsMessage) {
        throw new Error('Invalid request params: no `params.request.params[1]` to sign');
    }

    const requestParams = JSON.parse(requestParamsMessage) as TypedDataDefinition;

    if (!requestParams || !requestParams.message || !requestParams.types || !requestParams.primaryType) {
        throw new Error('Invalid request params: missing required fields');
    }

    const signature = await walletClient.signTypedData({
        account,
        message: requestParams.message,
        types: requestParams.types,
        primaryType: requestParams.primaryType,
        domain: requestParams.domain,
    });

    if (!signature) {
        throw new Error('Error in handleEthSignTypedData: signature is empty');
    }

    return createSuccessResponse(id, signature);
};

export default handleEthSignTypedData;
