export const getNameByChainId = (chainId: number) => {
    switch (chainId) {
        case 1:
            return 'ethereum';
        case 11155111:
            return 'sepolia';
        case 137:
            return 'polygon';
        case 59141:
            return 'linea-sepolia';
        case 59144:
            return 'linea';
        case 80002:
            return 'amoy';
        case 8453:
            return 'base';
        case 56:
            return 'binance';
        case 100:
            return 'gnosis';
        case 10:
            return 'optimism';
        case 534352:
            return 'scroll';
        case 42161:
            return 'arbitrum';
        default:
            return 'unknown';
    }
};
