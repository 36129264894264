import { JsonRpcResult, JsonRpcError, JsonRpcResponse } from '@walletconnect/jsonrpc-utils';
import { web3wallet } from './createWeb3Wallet';

export const JSON_RPC_VERSION = '2.0';

export const createSuccessResponse = <T = any>(id: number, result: T): JsonRpcResult<T> => {
    return {
        id,
        jsonrpc: JSON_RPC_VERSION,
        result,
    };
};

export const createErrorResponse = (id: number, message: string, error?: Error): JsonRpcError => ({
    id,
    jsonrpc: JSON_RPC_VERSION,
    error: {
        // TODO: add dynamic error codes
        code: 5000,
        message,
        data: error?.message,
    },
});

export const respondToWeb3 = async (topic: string, response: JsonRpcResponse) => {
    try {
        await web3wallet.respondSessionRequest({ topic, response });
    } catch (error: any) {
        throw new Error('Error responding to session request:', error);
    }
};
