import { Address, encodeFunctionData, erc20Abi } from 'viem';

export const getApproveTokenSpendTransaction = (contractAddress: Address, sendAddress: Address, amount: bigint) => {
    const approveData = encodeFunctionData({
        abi: erc20Abi,
        functionName: 'approve',
        args: [contractAddress, amount],
    });

    return {
        to: sendAddress,
        value: 0n,
        data: approveData,
    };
};
