import pLimit from 'p-limit';

const rateLimit = pLimit(10); // allows 10 concurrent requests

interface RpcError {
    message: string;
    patterns: string[];
    recoverable: boolean;
}

const httpRpcErrors: Record<number, RpcError> = {
    400: {
        recoverable: false,
        patterns: ['400'],
        message:
            'Bad request: Incorrect HTTP Request type or invalid characters, ensure that your request body and format is correct.',
    },
    401: {
        recoverable: false,
        patterns: [
            '401',
            'project id required in the url',
            'invalid project id',
            'invalid project id or project secret',
            'invalid JWT',
        ],
        message: 'Unauthorized: This can happen when one or multiple security requirements are not met.',
    },
    403: {
        recoverable: false,
        patterns: ['403'],
        message:
            'Forbidden: The request was intentionally refused due to specific settings mismatch, check your key settings.',
    },
    404: {
        recoverable: false,
        patterns: ['404'],
        message: "RPC endpoint doesn't exist",
    },
    405: {
        recoverable: false,
        patterns: ['405'],
        message: 'HTTP Method Not Allowed',
    },
    429: {
        recoverable: true,
        patterns: ['429', 'project ID request rate exceeded', 'daily request count exceeded', 'request rate limited'],
        message:
            'Too Many Requests: The daily request total or request per second are higher than your plan allows. Refer to the Avoid rate limiting topic for more information.',
    },
    500: {
        recoverable: true,
        patterns: ['500'],
        message: 'Internal Server Error: Error while processing the request on the server side.',
    },
    502: {
        recoverable: true,
        patterns: ['502'],
        message:
            'Bad Gateway: Indicates a communication error which can have various causes, from networking issues to invalid response received from the server.',
    },
    503: {
        recoverable: true,
        patterns: ['503', 'HTTP status 503 Service Unavailable', 'Service Unavailable', 'service unavailable'],
        message: 'Service Unavailable: Indicates that the server is not ready to handle the request.',
    },
    504: {
        recoverable: true,
        patterns: ['504'],
        message:
            'Gateway Timeout: The request ended with a timeout, it can indicate a networking issue or a delayed or missing response from the server.',
    },
} as const;

export function viemDebounce(client: any) {
    const proxyHandler = {
        get(target: any, prop: string | symbol) {
            if (typeof target[prop] === 'function') {
                return (...args: any[]) => {
                    return rateLimit(async () => {
                        try {
                            const result = await target[prop](...args);

                            return result;
                        } catch (err) {
                            const error = err as Error;
                            const recoverableError = Object.values(httpRpcErrors).find(({ patterns }) =>
                                patterns.some(pattern => error.message.includes(pattern)),
                            );

                            console.log('recoverableError:', recoverableError, 'error:', error);

                            if (recoverableError && recoverableError.recoverable) {
                                console.warn('Recoverable error occurred, retrying:', recoverableError.message);
                                return target[prop](...args); // retry the request
                            }

                            throw error; // rethrow non-recoverable error
                        }
                    });
                };
            }

            return target[prop];
        },
    };

    return new Proxy(client, proxyHandler);
}
