import { createSmartAccountClientWithMiddleware } from '@/helpers/Vault/createSmartAccountClientWithMiddleware';
import { TSwapTransactionParams, TTransactionCommonParams } from '../types';
import { TCall } from '@/helpers/Vault/types';

export const handleSwapTxConfigs = async ({
    kernelAccount,
    requestParams,
    smartAccount,
    activeChain,
    transactionModeConfigs,
}: TSwapTransactionParams & TTransactionCommonParams) => {
    if (!activeChain) throw new Error('Chain not initialized');

    const swapTransaction: TCall = {
        to: requestParams.to,
        value: BigInt(requestParams.value || 0n),
        data: requestParams.data || '',
    };

    let swapSmartAccount = smartAccount;

    if (transactionModeConfigs?.base !== transactionModeConfigs?.swap) {
        swapSmartAccount = await createSmartAccountClientWithMiddleware({
            activeChain,
            kernelAccount,
        });
    }

    return {
        transactions: [swapTransaction],
        transactionAccount: swapSmartAccount,
        txSubType: 'defi',
    };
};
