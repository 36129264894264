import { getUnlockAssetsTransactions } from '@/helpers/Vault/getUnlockAssetsTransactions';
import { TTransactionCommonParams, TUnLockTransactionParams } from '../types';
import { createSmartAccountClientWithMiddleware } from '@/helpers/Vault/createSmartAccountClientWithMiddleware';
import { Address } from 'viem';

export const handleUnLockTxConfigs = async ({
    assets,
    balances,
    contractAddress,
    kernelAccount,
    smartAccount,
    activeChain,
    transactionModeConfigs,
}: TUnLockTransactionParams & TTransactionCommonParams) => {
    const unlockTransactions = getUnlockAssetsTransactions(contractAddress as Address, assets, balances);

    let unlockAccount = smartAccount;

    if (transactionModeConfigs?.base !== transactionModeConfigs?.unlock) {
        unlockAccount = await createSmartAccountClientWithMiddleware({
            activeChain,
            kernelAccount,
        });
    }
    return {
        transactions: unlockTransactions,
        transactionAccount: unlockAccount,
        txSubType: 'unlock_token',
        balances,
    };
};
