import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { SessionTypes } from '@walletconnect/types';
import { Chain } from 'viem';
import SettingsStore from '@/store/SettingsStore';
import { Web3WalletTypes } from '@walletconnect/web3wallet';
import { chains } from '@/config/chains';
import handleApproveSession from '@/providers/walletConnect/helpers/handleApproveSession';
import { useSnapshot } from 'valtio';
import { web3wallet } from '@/providers/walletConnect/helpers/createWeb3Wallet';
import ActiveSessionsState from '@/store/ActiveSessionsStore';

interface TuseHandleConnectionReturn {
    step: string;
    setStep: Dispatch<SetStateAction<string>>;
    currentProposal: Web3WalletTypes.SessionProposal | undefined;
    setCurrentProposal: Dispatch<SetStateAction<Web3WalletTypes.SessionProposal | undefined>>;
    supportedRequestedChains: Chain[] | undefined;
    handleCloseConnectModal: () => void;
    handleAccept: () => Promise<void>;
    onSessionProposal: (proposal: Web3WalletTypes.SessionProposal) => void;
}

const MAX_RETRIES = 3;
const RETRY_DELAY_MS = 1000;

export const useHandleConnection = (
    accountAddress: string,
    setActiveSessions: (activeSessions: [] | SessionTypes.Struct[]) => void,
    setOpenConnect?: Dispatch<SetStateAction<boolean>>,
): TuseHandleConnectionReturn => {
    const { activeSessions } = useSnapshot(ActiveSessionsState.state);
    const { activeChain } = useSnapshot(SettingsStore.state);
    const [step, setStep] = useState<string>('connect');
    const [currentProposal, setCurrentProposal] = useState<Web3WalletTypes.SessionProposal | undefined>(undefined);
    const [retryCount, setRetryCount] = useState(0);
    const [supportedRequestedChainsArray, setSupportedRequestedChainsArray] = useState<Chain[] | undefined>(undefined);

    const handleCloseConnectModal = useCallback(() => {
        setOpenConnect && setOpenConnect(false);
        setCurrentProposal(undefined);
        setStep('connect');
    }, [setOpenConnect]);

    const handleAccept = useCallback(async () => {
        try {
            const session = await handleApproveSession(accountAddress, currentProposal);

            if (session) {
                setActiveSessions([...(activeSessions as any), session]);

                setCurrentProposal(undefined);
                setStep('connected');
                setTimeout(() => {
                    setOpenConnect && setOpenConnect(false);
                    setStep('connect');
                }, 2000);
            } else {
                setCurrentProposal(undefined);
            }
        } catch (error) {
            console.log('error', error);
            setStep('failed');
            setCurrentProposal(undefined);
        }
    }, [activeChain, accountAddress, setOpenConnect, currentProposal, activeSessions]);

    const onSessionProposal = useCallback(
        (proposal: Web3WalletTypes.SessionProposal) => {
            setCurrentProposal(proposal);

            const requestedChains =
                Object.keys(proposal.params.requiredNamespaces).length !== 0 &&
                proposal?.params.requiredNamespaces?.['eip155']?.chains?.length !== 0
                    ? proposal?.params.requiredNamespaces?.['eip155']?.chains
                    : proposal?.params.optionalNamespaces?.['eip155']?.chains;

            const isRequestedChainCurrentChain = requestedChains?.find(
                (chainName: string) => chainName === `eip155:${activeChain?.id}`,
            );
            const supportedRequestedChains = chains.filter(chain => requestedChains?.includes(`eip155:${chain.id}`));

            setSupportedRequestedChainsArray(supportedRequestedChains);

            if (isRequestedChainCurrentChain) {
                setStep('confirmation');
            } else if (supportedRequestedChains?.length) {
                setStep('change-network');
            } else {
                setStep('network-not-supported');
            }
        },
        [setStep, activeChain, setCurrentProposal, setSupportedRequestedChainsArray],
    );

    useEffect(() => {
        const fetchActiveSessions = async () => {
            if (web3wallet && accountAddress) {
                try {
                    const activeSessions = web3wallet.getActiveSessions();
                    const valueActiveSessions = Object.values(activeSessions);

                    if (valueActiveSessions.length) {
                        setActiveSessions(valueActiveSessions);
                        setRetryCount(0); // Reset retry count on success
                    }
                } catch (error) {
                    if (retryCount < MAX_RETRIES) {
                        setTimeout(() => {
                            setRetryCount(retryCount + 1);
                        }, RETRY_DELAY_MS);
                    } else {
                        console.error('Failed to fetch active sessions after retries:', error);
                    }
                }
            }
        };

        fetchActiveSessions();
    }, [setActiveSessions, currentProposal, web3wallet, accountAddress, retryCount]);

    return {
        step,
        setStep,
        handleAccept,
        currentProposal,
        onSessionProposal,
        setCurrentProposal,
        handleCloseConnectModal,
        supportedRequestedChains: supportedRequestedChainsArray,
    };
};
