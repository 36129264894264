import { SmartAccountClient } from 'permissionless';
import { EntryPoint } from 'permissionless/types';
import { Address, Chain, Hex, Transport, WalletClient } from 'viem';
import { UserOperation, formatUserOperation, formatUserOperationRequest } from 'viem/account-abstraction';
import { SmartAccount } from 'permissionless/accounts';
import { ENTRYPOINT_ADDRESS_V06, signUserOperationHashWithECDSA } from 'permissionless/utils';
import axios from 'axios';
import { TCall } from './types';
import { callsToSmartAccountTransaction } from './callsToSmartAccountTransaction';
import { estimateSmartAccountTransaction } from './estimateSmartAccountTransaction';
import { TFeeType, TUserOpType } from '@/app/api/v1/private/account/userop/helpers/types';

export const sendSmartAccountTransaction = async (
    accountClient: SmartAccountClient<EntryPoint, Transport, Chain, SmartAccount<EntryPoint>>,
    transactions: TCall[],
    userOpType: TUserOpType,
    preferredFeeType: TFeeType | undefined,
    feeTokenAddress: Address | undefined,
    activeChain: Chain,
    walletClient: WalletClient,
) => {
    const partialUserOperation = await callsToSmartAccountTransaction(accountClient, transactions);
    const gasParameters = await estimateSmartAccountTransaction(
        partialUserOperation,
        userOpType,
        preferredFeeType,
        feeTokenAddress,
        activeChain,
    );

    const userOperation = formatUserOperation({
        ...partialUserOperation,
        // Convert nonce to allow formatting UserOp
        nonce: ('0x' + partialUserOperation.nonce.toString(16)) as Hex,
        ...gasParameters,
    }) as Required<UserOperation<'0.6'>>;

    const signature = await signUserOperationHashWithECDSA({
        client: walletClient,
        account: walletClient.account!,
        userOperation: userOperation,
        chainId: activeChain.id,
        entryPoint: ENTRYPOINT_ADDRESS_V06,
    });

    // NOTE: this is a ZeroDev Kernel specific signature format
    userOperation.signature = `0x00000000${signature.slice(2)}`;

    const userOperationSerialized = formatUserOperationRequest(userOperation);

    const response = await axios.post('/api/v1/private/account/userop/send', {
        userOperation: userOperationSerialized,
        userOpType,
        activeChainId: activeChain.id,
    });

    const { txHash, success, message } = response.data;

    if (!success) {
        throw new Error(`Error sending user operation: ${message}`);
    }

    return txHash;
};
