import { FC, useMemo, useCallback } from 'react';
import { Alert } from '@/components/Vault/Alert';
import { useSnapshot } from 'valtio';
import AlertsState, { IAlertsState } from '@/store/AlertStore';

interface AlertsProps {}

export const Alerts: FC<AlertsProps> = () => {
    const { alerts } = useSnapshot(AlertsState.state) as IAlertsState;

    const handleCloseAlert = useCallback(() => {
        AlertsState.deleteAlert();
    }, []);

    const handleCloseAlertByClick = useCallback((alertIndex: number) => {
        AlertsState.deleteAlertByIndex(alertIndex);
    }, []);

    const renderAlerts = useMemo(() => {
        return alerts.map((alert: any, index: number) => {
            const alertParams = {
                alertDisplayTime: !alert.autoCloseDisabled ? alert.alertDisplayTime || '3' : null,
                type: alert.type,
                messageType: alert.messageType,
                messageText: alert.messageText,
                onClose: handleCloseAlert,
                onCloseByClick: () => handleCloseAlertByClick(index),
                messageTypeClassName: alert.messageTypeClassName
                    ? alert.messageTypeClassName
                    : 'text-sm font-medium pb-1 break-words text-neutral-control-layer-color-100',
                rootClassName: alert.rootClassName
                    ? alert.rootClassName
                    : 'flex items-start justify-between w-[300px] xs:w-[360px] max-w-[360px] h-max p-4 bg-body-background-color shadow-lg group',
                ...(alert.alertIconBlockClassName && {
                    alertIconBlockClassName: alert.alertIconBlockClassName,
                }),
                ...(alert.alertIconClassName && {
                    alertIconClassName: alert.alertIconClassName,
                }),
                ...(alert.contentBlockClassName && {
                    contentBlockClassName: alert.contentBlockClassName,
                }),
                ...(alert.closeIconBlockClassName && {
                    closeIconBlockClassName: alert.closeIconBlockClassName,
                }),
                ...(alert.closeIconClassName && {
                    closeIconClassName: alert.closeIconClassName,
                }),
                closeIconClassName: alert.closeIconClassName
                    ? alert.closeIconClassName
                    : 'h-5 w-5 text-neutral-control-layer-color-50 cursor-pointer',
                messageTextClassName: 'text-sm text-neutral-control-layer-color-60 break-words',
                defaultSuccessColor: 'text-system-green-60',
                defaultInfoColor: 'text-system-blue-90',
                defaultWarnColor: 'text-system-yellow-60',
                defaultErrorColor: 'text-system-red-60',
            };

            return (
                <div onClick={handleCloseAlert} className="relative first:mt-2 mt-1 cursor-pointer" key={index}>
                    <Alert {...alertParams} />
                </div>
            );
        });
    }, [alerts]);

    return (
        <div data-testid="root" className="fixed z-[9999999] right-0">
            {renderAlerts}
        </div>
    );
};
