import { SessionTypes } from '@walletconnect/types';
import { proxy } from 'valtio';

export interface IActiveSessionsState {
    activeSessions: SessionTypes.Struct[];
}

const state = proxy<IActiveSessionsState>({
    activeSessions: [],
});

const ActiveSessionsState = {
    state,
    setActiveSessions(activeSessions: SessionTypes.Struct[] | []) {
        state.activeSessions = activeSessions;
    },
};

export default ActiveSessionsState;
