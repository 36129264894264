import { Address, encodeFunctionData } from 'viem';
import { TCall } from './types';
import VOUCHER_ROUTER_ABI from '@/utils/IVoucher.abi.json';

export const getDailyClaimTransaction = (voucherRouterAddress: Address, voucher: any): TCall => {
    const callData = encodeFunctionData({
        abi: VOUCHER_ROUTER_ABI,
        functionName: 'use',
        args: [[voucher]],
    });

    return {
        to: voucherRouterAddress,
        value: 0n,
        data: callData,
    };
};
