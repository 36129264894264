import {
    TEstimateResponse,
    TEstimates,
    TUserOpType,
    TFeeType,
} from '@/app/api/v1/private/account/userop/helpers/types';
import axios from 'axios';
import { Address, Chain, toHex } from 'viem';
import { TPartialUserOperation } from './types';

// TODO: refactor to take an object as a parameter
export const estimateSmartAccountTransaction = async (
    partialUserOperation: TPartialUserOperation,
    userOpType: TUserOpType,
    preferredFeeType: TFeeType | undefined,
    feeTokenAddress: Address | undefined,
    activeChain: Chain,
): Promise<TEstimates> => {
    const estimateResponse = (await axios.post('/api/v1/private/account/userop/estimate', {
        userOperation: {
            ...partialUserOperation,
            nonce: toHex(partialUserOperation.nonce),
        },
        preferredFeeType,
        userOpType,
        feeTokenAddress,
        activeChainId: activeChain.id,
    })) as TEstimateResponse;

    return estimateResponse.data.estimates;
};
