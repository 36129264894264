import { type Address } from 'viem';

/**
 * Default addresses for kernel smart account
 */
export const KERNEL_ADDRESSES: {
    ECDSA_VALIDATOR: Address;
    ACCOUNT_LOGIC: Address;
    FACTORY_ADDRESS: Address;
} = {
    ECDSA_VALIDATOR: '0xd9AB5096a832b9ce79914329DAEE236f8Eea0390',
    ACCOUNT_LOGIC: '0xd3082872F8B06073A021b4602e022d5A070d7cfC',
    FACTORY_ADDRESS: '0x5de4839a76cf55d0c90e2061ef4386d962E15ae3',
};

// Account logic for v2_2: ACCOUNT_V2_2_LOGIC: '0x0DA6a956B9488eD4dd761E59f52FDc6c8068E6B5',

// 0x6492649264926492649264926492649264926492649264926492649264926492
export const MAGIC_BYTES = '6492649264926492649264926492649264926492649264926492649264926492';
