import { Address, encodeFunctionData } from 'viem';

export const getUnlockAssetTransaction = (contractAddress: Address, assetAddress: Address, parsedAmount: bigint) => {
    const withdrawData = encodeFunctionData({
        abi: [
            {
                type: 'function',
                name: 'withdraw',
                inputs: [
                    {
                        name: 'token',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'amount',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'payable',
            },
        ],
        args: [assetAddress as `0x${string}`, parsedAmount as any],
    });

    return {
        to: contractAddress,
        value: 0n,
        data: withdrawData,
    };
};
