import { Web3Wallet, IWeb3Wallet } from '@walletconnect/web3wallet';
import { Core } from '@walletconnect/core';

export let web3wallet: IWeb3Wallet;

const core = new Core({
    projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_APP_ID ?? '',
    relayUrl: process.env.NEXT_PUBLIC_WALLET_CONNECT_RELAY_URL ?? '',
});

export async function createWeb3Wallet() {
    web3wallet = await Web3Wallet.init({
        core,
        metadata: {
            name: process.env.NEXT_PUBLIC_WALLET_CONNECT_METADATA_NAME ?? '',
            description: process.env.NEXT_PUBLIC_WALLET_CONNECT_METADATA_DESCRIPTION ?? '',
            url: process.env.NEXT_PUBLIC_WALLET_CONNECT_METADATA_URL ?? '',
            icons: [process.env.NEXT_PUBLIC_WALLET_CONNECT_METADATA_ICON ?? ''],
        },
    });
}
