'use client';

import { HeaderOptions, Layout } from '@/components/Layout';
import { useSessionRequestQueue } from '@/context/sessionRequestQueue';
import useConnect from '@/hooks/Vault/useConnect';
import { useHandleConnection } from '@/hooks/Vault/useHandleConnection';
import { useHandleSessionRequests } from '@/hooks/Vault/useHandleSessionRequests';
import useInitialization from '@/hooks/Vault/useInitialization';
import { useSetMobileDevice } from '@/hooks/Vault/useMobileDevice';
import { WalletConnectProvider } from '@/providers/walletConnect';
import AccountStore from '@/store/AccountStore';
import ActiveSessionsState from '@/store/ActiveSessionsStore';
import { LockedAssetsStore } from '@/store/LockedAssetsStore';
import ModalStore from '@/store/ModalStore';
import QuestStore from '@/store/QuestStore';
import SettingsStore from '@/store/SettingsStore';
import UserStore from '@/store/UserStore';
import { Alerts } from '@/widgets/Alerts';
import { IHeaderProps } from '@/widgets/Header';
import { usePrivy, useWallets } from '@privy-io/react-auth';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useSnapshot } from 'valtio';

const CookiesDisclaimer = dynamic(() => import('@/widgets/Cookies').then((mod) => mod.CookiesDisclaimer), {
    ssr: false,
});
const RequestModal = dynamic(() => import('@/widgets/RequestModal'), { ssr: false });
const AnimatePresence = dynamic(() => import('framer-motion').then((mod) => mod.AnimatePresence), { ssr: false });
const ConnectModal = dynamic(() => import('@/widgets/ConnectModal'), { ssr: false });
const WrongNetwork = dynamic(() => import('@/widgets/Assets/modal/wrongNetwork'), { ssr: false });
const Receive = dynamic(() => import('@/widgets/Assets/modal/receive'), { ssr: false });
const ImportTokens = dynamic(() => import('@/widgets/Assets/modal/importTokens'), { ssr: false });

interface ILayoutWidgetProps {
    children: React.ReactNode;
    openConnect?: boolean;
    setOpenConnect?: Dispatch<SetStateAction<boolean>>;
    containerClassName?: string;
    headerOptions?: HeaderOptions;
    hideOptionsMenu?: boolean;
    hideFooter?: boolean;
    hideHeader?: boolean;
    isVaultLayout?: boolean;
    hideHeaderBorder?: boolean;
}

export const LayoutWidget: FC<ILayoutWidgetProps> = ({
    openConnect,
    setOpenConnect,
    children,
    containerClassName,
    headerOptions,
    hideFooter,
    hideHeader,
    isVaultLayout,
    hideOptionsMenu,
    hideHeaderBorder,
}) => {
    useInitialization();
    const isMobile = useSetMobileDevice();
    const router = useRouter();
    const [connect] = useConnect();
    const { login, isModalOpen, logout } = usePrivy();
    const { activeChain, prevChainId } = useSnapshot(SettingsStore.state);
    const { accountAddress } = useSnapshot(AccountStore.state);
    const { local, wrongNetwork, importToken } = useSnapshot(ModalStore.state);
    const setActiveSessions = ActiveSessionsState.setActiveSessions;
    const { queue } = useSessionRequestQueue();
    const [onSmartAccountOnChange, setOnSmartAccountOnChange] = useState<boolean>(true);

    /**
     *
     * Note that when you connect a new wallet, it becomes first in the wallets array.
     */
    const { wallets } = useWallets();

    const [accountLoading, setAccountLoading] = useState<boolean>(false);

    // CREATE/CONNECT TO SMART ACCOUNT
    useEffect(() => {
        const handleConnect = async () => {
            setOnSmartAccountOnChange(true);
            await connect(wallets, activeChain);
        };

        if (activeChain && !onSmartAccountOnChange) {
            handleConnect();
        }
    }, [wallets, accountAddress, activeChain, onSmartAccountOnChange, connect]);

    useEffect(() => {
        if (activeChain && prevChainId && activeChain.id !== prevChainId) {
            setOnSmartAccountOnChange(false);
        }
    }, [activeChain, prevChainId]);

    useEffect(() => {
        setAccountLoading(isModalOpen);
    }, [isModalOpen]);

    const { openRequest, onSessionRequest, handleApproveSessionRequest, handleReject, handleCloseSessionRequestModal } =
        useHandleSessionRequests(accountAddress);

    const {
        step,
        setStep,
        handleAccept,
        currentProposal,
        setCurrentProposal,
        handleCloseConnectModal,
        supportedRequestedChains,
        onSessionProposal,
    } = useHandleConnection(accountAddress, setActiveSessions, setOpenConnect);

    const onLogOut = useCallback(async () => {
        await logout();
        AccountStore.setSmartAccount({});
        AccountStore.setAccountAddress('');
        setAccountLoading(false);
        setOpenConnect && setOpenConnect(false);
        setActiveSessions([]);
        setCurrentProposal(undefined);
        handleCloseConnectModal();
        handleCloseSessionRequestModal();
        QuestStore.resetQuestsState();
        UserStore.resetUser();
        LockedAssetsStore.reset();
        router.push('/');
    }, [
        logout,
        setOpenConnect,
        setActiveSessions,
        setCurrentProposal,
        handleCloseConnectModal,
        handleCloseSessionRequestModal,
        router,
    ]);

    const handleLogin = useCallback(async () => {
        try {
            setAccountLoading(true);
            login();
        } catch (error) {
            console.log('Error while authorization', error);
            setAccountLoading(false);
        }
    }, [login, setAccountLoading]);

    const closeOpenReceiveHandler = useCallback(() => {
        ModalStore.closeLocal();
    }, []);

    const closeWrongNetworkHandler = useCallback(() => {
        ModalStore.closeWrongNetwork();
    }, []);

    const headerProps: IHeaderProps = useMemo(
        () => ({
            onLogOut: onLogOut,
            connect: handleLogin,
            loading: accountLoading,
            accountAddress: accountAddress,
            setOpenConnect: setOpenConnect,
            hideBorder: hideHeaderBorder,
        }),
        [onLogOut, handleLogin, accountLoading, accountAddress, setOpenConnect, hideHeaderBorder],
    );

    return (
        <WalletConnectProvider onSessionRequest={onSessionRequest} onSessionProposal={onSessionProposal}>
            <Alerts />
            <Layout
                vaultHeaderProps={headerProps}
                className={containerClassName}
                headerOptions={headerOptions}
                hideFooter={hideFooter}
                hideHeader={hideHeader}
                isVaultLayout={isVaultLayout}
                hideOptionsMenu={hideOptionsMenu}>
                {children}
            </Layout>
            {openConnect && (
                <ConnectModal
                    step={step}
                    setStep={setStep}
                    handleAccept={handleAccept}
                    currentProposal={currentProposal}
                    setCurrentProposal={setCurrentProposal}
                    handleCloseModal={handleCloseConnectModal}
                    supportedRequestedChains={supportedRequestedChains}
                />
            )}
            {openRequest && (
                <RequestModal
                    sessionRequests={queue}
                    handleAccept={handleApproveSessionRequest}
                    handleReject={handleReject}
                    handleCloseModal={handleCloseSessionRequestModal}
                />
            )}
            {local?.open && (
                <AnimatePresence>
                    <Receive
                        accountAddress={accountAddress}
                        handleCloseModal={closeOpenReceiveHandler}
                        isMobile={isMobile}
                    />
                </AnimatePresence>
            )}
            {wrongNetwork.open && <WrongNetwork handleCloseModal={closeWrongNetworkHandler} />}
            {importToken.open && <ImportTokens />}
            <CookiesDisclaimer />
        </WalletConnectProvider>
    );
};

export default LayoutWidget;
