import React, { FC, useEffect } from 'react';
import { web3wallet } from '@/providers/walletConnect/helpers/createWeb3Wallet';
import { Web3WalletTypes } from '@walletconnect/web3wallet';

export interface IWalletConnectProviderProps {
    children: React.ReactNode;
    onSessionRequest: (event: Web3WalletTypes.SessionRequest) => void;
    onSessionProposal: (proposal: Web3WalletTypes.SessionProposal) => void;
}

// TODO: handle sessionProposal, activeSessions
export const WalletConnectProvider: FC<IWalletConnectProviderProps> = ({
    children,
    onSessionRequest,
    onSessionProposal,
}) => {
    useEffect(() => {
        try {
            if (!web3wallet) {
                return;
            }

            web3wallet.on('session_proposal', onSessionProposal);
            web3wallet.on('session_request', onSessionRequest);
        } catch (error) {
            console.error('Failed to initialize Web3Wallet:', error);
        }
    }, [onSessionProposal, onSessionRequest, web3wallet]);

    return <>{children}</>;
};
