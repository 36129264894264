import { SmartAccountClient } from 'permissionless';
import { EntryPoint, UserOperation } from 'permissionless/types';
import { Chain, Transport } from 'viem';
import { SmartAccount } from 'permissionless/accounts';
import { TCall, TPartialUserOperation } from './types';

// TODO: rename to `callsToPartialUserOperation`
export const callsToSmartAccountTransaction = async (
    accountClient: SmartAccountClient<EntryPoint, Transport, Chain, SmartAccount<EntryPoint>>,
    calls: TCall[],
): Promise<TPartialUserOperation> => {
    if (!accountClient.account) {
        throw new Error('Smart Account is undefined');
    }

    const senderAddress = accountClient.account.address;
    const nonce = await accountClient.account.getNonce(); // BigInt

    const initCode = await accountClient.account.getInitCode();
    const partialUserOperation: TPartialUserOperation = {
        sender: senderAddress,
        nonce,
        initCode: initCode,
        callData: await accountClient.account.encodeCallData(calls),
        paymasterAndData: '0x',
        signature: '0x',
    };

    const dummySignature = await accountClient.account.getDummySignature(
        partialUserOperation as unknown as UserOperation<'v0.6'>,
    );

    partialUserOperation.signature = dummySignature;

    return partialUserOperation;
};
