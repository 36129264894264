import { Address, Chain, encodeFunctionData, erc20Abi, parseEther, parseUnits } from 'viem';
import { TCall } from './types';
import { TTokenSend } from '@/types';

export const getWithdrawTransaction = (tokens: TTokenSend[], addressTo: Address, activeChain?: Chain): TCall[] => {
    return tokens.map((token) => {
        if (token.symbol?.toLowerCase() === activeChain?.nativeCurrency?.symbol?.toLowerCase()) {
            return {
                to: addressTo as `0x${string}`,
                value: parseEther(token.amount),
                data: '0x' as `0x${string}`,
            };
        } else {
            const amountInTokenUnits = parseUnits(token.amount, token.decimals);
            const txData = encodeFunctionData({
                abi: erc20Abi,
                functionName: 'transfer',
                args: [addressTo, amountInTokenUnits],
            });

            return {
                to: token.address as `0x${string}`,
                value: BigInt(0),
                data: txData,
            };
        }
    });
};
