import { Address, Chain, Transport, WalletClient } from 'viem';

import { SmartAccount } from 'permissionless/accounts';
import { SmartAccountClient } from 'permissionless';
import { Web3WalletTypes } from '@walletconnect/web3wallet';
import { JsonRpcResult } from '@walletconnect/jsonrpc-utils';
import { EntryPoint } from 'permissionless/types';
import { TransactionHistoryPayload } from '@/store/api/history/history';
import { handleTransaction as sendTransaction } from '@/hooks/Vault/useHandleTransactions';
import { TTransactionConfigs } from '@/store/SettingsStore';
import { createSuccessResponse } from './response';

const handleSessionRequest = async (
    event: Web3WalletTypes.SessionRequest,
    smartAccount: SmartAccountClient<EntryPoint, Transport, Chain, SmartAccount<EntryPoint>> | null,
    _mode: string,
    kernelAccount: SmartAccount<EntryPoint> | null,
    walletClient: WalletClient,
    setTransactionHistory: (payload: TransactionHistoryPayload) => Promise<any>,
    transactionConfigs?: TTransactionConfigs,
    feeTokenAddress?: Address | undefined,
    activeChain?: Chain,
): Promise<JsonRpcResult> => {
    if (!smartAccount) {
        throw new Error('Smart account is not initialized');
    }

    const { params, id } = event;
    const requestParamsMessage = params.request.params[0];

    const handleTransaction = async (transactionType: 'swap') => {
        const txHash = await sendTransaction({
            walletClient,
            kernelAccount,
            smartAccount,
            setTransactionHistory,
            transactionType,
            transactionModeConfigs: transactionConfigs,
            requestParams: requestParamsMessage,
            activeChain,
            feeTokenAddress,
        });

        return { transactionHash: txHash };
    };

    const receipt: { transactionHash: `0x${string}` | undefined } = await handleTransaction('swap');

    return createSuccessResponse(id, receipt.transactionHash);
};

export default handleSessionRequest;
