import { Web3WalletTypes } from '@walletconnect/web3wallet';
import { buildApprovedNamespaces } from '@walletconnect/utils';
import { web3wallet } from './createWeb3Wallet';
import { chains } from '../../../config/chains';

const APPROVE_SESSION_ERROR = 'Error: "currentProposal" or "web3wallet" or "smartAccountAddress" is not defined';

const handleApproveSession = async (
    smartAccountAddress: string | null,
    currentProposal?: Web3WalletTypes.SessionProposal,
) => {
    try {
        if (currentProposal && web3wallet && smartAccountAddress) {
            const eip155Chains = chains.map(chain => `eip155:${chain.id}`);
            const accounts = eip155Chains.map(chain => `${chain}:${smartAccountAddress}`);

            const approvedNamespaces = buildApprovedNamespaces({
                proposal: currentProposal.params,
                supportedNamespaces: {
                    eip155: {
                        chains: eip155Chains,
                        methods: ['eth_sendTransaction', 'eth_signTypedData', 'eth_signTypedData_v4', 'personal_sign'],
                        events: ['accountsChanged', 'chainChanged'],
                        accounts: accounts,
                    },
                },
            });

            const session = await web3wallet.approveSession({
                id: currentProposal.id,
                namespaces: approvedNamespaces,
                // relayProtocol: relays[0].protocol,
            });

            return session;
        }
    } catch (error) {
        console.log('connection error', error);
        throw new Error(APPROVE_SESSION_ERROR);
    }
};

export default handleApproveSession;
