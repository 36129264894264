import { TLockTransactionParams, TTransactionCommonParams } from '../types';
import { getApproveTokenSpendTransaction } from '@/helpers/Vault/getApproveTokenSpendTransaction';
import { getDepositTokenTransaction } from '@/helpers/Vault/getDepositTokenTransaction';
import { createSmartAccountClientWithMiddleware } from '@/helpers/Vault/createSmartAccountClientWithMiddleware';
import { TCall } from '@/helpers/Vault/types';
import { Address, parseUnits } from 'viem';
import { analytics } from '@/helpers/analytics';

export const handleLockTxConfigs = async ({
    amount,
    contractAddress,
    kernelAccount,
    smartAccount,
    token,
    activeChain,
    transactionModeConfigs,
}: TLockTransactionParams & TTransactionCommonParams) => {
    const parsedAmount = parseUnits(amount, token.decimals);

    let approveTransaction: TCall | null = null;
    let depositTransaction: TCall | null = null;

    if (token.symbol?.toLowerCase() !== activeChain?.nativeCurrency?.symbol?.toLowerCase()) {
        approveTransaction = getApproveTokenSpendTransaction(
            contractAddress as Address,
            token.address as Address,
            parsedAmount,
        );
    }

    depositTransaction = getDepositTokenTransaction(contractAddress as Address, token, parsedAmount, activeChain!);

    const lockTransactions = [depositTransaction];

    if (approveTransaction) {
        lockTransactions?.unshift(approveTransaction);
    }

    let lockAccount = smartAccount;

    if (transactionModeConfigs?.base !== transactionModeConfigs?.lock) {
        lockAccount = await createSmartAccountClientWithMiddleware({
            activeChain,
            kernelAccount,
        });
    }

    if (typeof window !== 'undefined') {
        const prevPage = sessionStorage.getItem('previousPage');

        analytics({
            type: 'otherEvent',
            name: 'seasons_lock',
            params: {
                chain_id: activeChain?.id,
                asset: token.symbol,
                amount: amount,
                // TODO: use season_id from the SeasonsStore
                season_id: 2,
                source_page: prevPage || window.location.pathname,
            },
        });

        sessionStorage.removeItem('previousPage');
    }

    return {
        transactions: lockTransactions,
        transactionAccount: lockAccount,
        txSubType: 'lock_token',
    };
};
