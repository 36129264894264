import { SessionTypes } from '@walletconnect/types';
import { Web3WalletTypes } from '@walletconnect/web3wallet';
import { JsonRpcResult } from '@walletconnect/jsonrpc-utils';
import { WalletClient } from 'viem';
import { SmartAccount } from 'viem/account-abstraction';
import { createSuccessResponse } from './response';

// TODO: why passing "accountAddress" fails on Privy side with
// "Must have a Privy wallet before signing" ?
const handlePersonalSign = async (
    walletClient: WalletClient,
    account: SmartAccount,
    event: Web3WalletTypes.SessionRequest,
    activeSessions: SessionTypes.Struct[],
): Promise<JsonRpcResult> => {
    const { topic, params, id } = event;
    const requestParamsMessage = params.request.params;

    const signature = await walletClient.signMessage({
        account,
        message: requestParamsMessage[0],
    });

    if (!signature) {
        throw new Error('Error in handlePersonalSign: signature is empty');
    }

    const requestDuckiesSession = activeSessions.find(
        (session: SessionTypes.Struct) =>
            session.peer.metadata.name === 'Yellow Duckies' &&
            session.topic === topic &&
            session.peer.metadata.url === 'https://www.yellow.org/canarynet/duckies',
    );

    const responseWr = createSuccessResponse(id, signature);
    const duckiesResponseWr = createSuccessResponse(id, {
        signature,
        EOAAddress: account.address,
    });

    return requestDuckiesSession ? duckiesResponseWr : responseWr;
};

export default handlePersonalSign;
