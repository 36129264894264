import { Chain, http } from 'viem';
import { ENTRYPOINT_ADDRESS_V06, createSmartAccountClient } from 'permissionless';
import { getNameByChainId } from '@/hooks/Vault/useConnect/getNameByChainId';

interface ISmartAccountClientWithMiddlewareParams {
    kernelAccount: any;
    activeChain: Chain | undefined;
}

// TODO: remove altogether
export const createSmartAccountClientWithMiddleware = async ({
    kernelAccount,
    activeChain,
}: ISmartAccountClientWithMiddlewareParams) => {
    const chainName = getNameByChainId(activeChain?.id || 0);

    const smartAccountClient = createSmartAccountClient({
        account: kernelAccount,
        chain: activeChain,
        entryPoint: ENTRYPOINT_ADDRESS_V06,
        // TODO: this is a stub. Remove it
        bundlerTransport: http(`https://api.pimlico.io/v2/${chainName}`, {
            timeout: 60_000,
        }),
    });

    return smartAccountClient;
};
