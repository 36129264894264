import { proxy } from 'valtio';

export interface Alert {
    type: string;
    messageType: string | React.ReactNode;
    messageText: string | React.ReactNode;
    autoCloseDisabled?: boolean;
    alertDisplayTime?: number | string;
    messageTypeClassName?: string;
    rootClassName?: string;
    alertIconBlockClassName?: string;
    alertIconClassName?: string;
    contentBlockClassName?: string;
    closeIconBlockClassName?: string;
    closeIconClassName?: string;
}

export interface IAlertsState {
    alerts: Alert[];
}

const state = proxy<IAlertsState>({
    alerts: [],
});

const AlertsState = {
    state,
    addAlert(alert: Alert) {
        state.alerts.push(alert);
    },
    deleteAlert() {
        state.alerts.pop();
    },
    deleteAlertByIndex(index: number) {
        state.alerts.splice(index, 1);
    },
};

export default AlertsState;
