import { createSmartAccountClientWithMiddleware } from '@/helpers/Vault/createSmartAccountClientWithMiddleware';
import { TDailyTransactionParams, TTransactionCommonParams } from '../types';
import { getDailyClaimTransaction } from '@/helpers/Vault/getDailyClaimTransaction';

export const handleDailyTxConfigs = async ({
    voucherRouterContractAddress,
    kernelAccount,
    smartAccount,
    voucher,
    activeChain,
    transactionModeConfigs,
}: TDailyTransactionParams & TTransactionCommonParams) => {
    const call = getDailyClaimTransaction(voucherRouterContractAddress, voucher);

    let dailyAccount = smartAccount;

    if (transactionModeConfigs?.base !== transactionModeConfigs?.daily_claim) {
        dailyAccount = await createSmartAccountClientWithMiddleware({
            activeChain,
            kernelAccount,
        });
    }

    return {
        transactions: [call],
        transactionAccount: dailyAccount,
        txSubType: 'daily_claim',
    };
};
