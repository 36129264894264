import AssetsStore, { IAssetsState } from '@/store/AssetsStore';
import { TSessionRequestQueue, useSessionRequestQueue } from '@/context/sessionRequestQueue';
import { useCallback, useMemo, useState } from 'react';

import AccountStore from '@/store/AccountStore';
import SettingsStore from '@/store/SettingsStore';
import { Web3WalletTypes } from '@walletconnect/web3wallet';
import { getBalances } from '../getTokenBalances';
import handleEthSignTypedData from '@/providers/walletConnect/helpers/handleEthSignTypedData';
import handlePersonalSign from '@/providers/walletConnect/helpers/handlePersonalSign';
import handleSessionRequest from '@/providers/walletConnect/helpers/handleSessionRequest';
import { useSnapshot } from 'valtio';
import { web3wallet } from '@/providers/walletConnect/helpers/createWeb3Wallet';
import ActiveSessionsState, { IActiveSessionsState } from '@/store/ActiveSessionsStore';
import { useSetTransactionHistory } from '@/store/api/history/history';
import { createErrorResponse, respondToWeb3 } from '@/providers/walletConnect/helpers/response';

interface TuseHandleSessionRequestsReturn {
    openRequest: boolean;
    onSessionRequest: (requestEvent: Web3WalletTypes.SessionRequest) => void;
    handleCloseSessionRequestModal: () => void;
    handleApproveSessionRequest: (mode: string, requestItem: TSessionRequestQueue) => Promise<void>;
    handleReject: (requestItem: TSessionRequestQueue) => Promise<void>;
}
export const useHandleSessionRequests = (accountAddress: string): TuseHandleSessionRequestsReturn => {
    const { enqueue, setQueue } = useSessionRequestQueue();
    const { setTransactionHistory } = useSetTransactionHistory();
    const { setBalances, setBalancesLoading } = AssetsStore;
    const { assets, balances } = useSnapshot<IAssetsState>(AssetsStore.state) as IAssetsState;
    const { smartAccount, kernelAccount, walletClient } = useSnapshot(AccountStore.state);
    const { activeChain, paymasterAddresses, transactionConfigs } = useSnapshot(SettingsStore.state);
    const { activeSessions } = useSnapshot(ActiveSessionsState.state) as IActiveSessionsState;

    const [openRequest, setOpenRequest] = useState<boolean>(false);

    const updateQueueItemStatus = useCallback(
        (id: number, status: string, successMessage?: string, error?: any) => {
            setQueue(prevQueue =>
                prevQueue.map(queueItem => {
                    if (queueItem.request.id === id) {
                        return {
                            ...queueItem,
                            status,
                            successMessage,
                            error: error || queueItem.error,
                        };
                    }
                    return queueItem;
                }),
            );
        },
        [setQueue],
    );

    const usdcToken = useMemo(() => assets?.find(asset => asset.symbol?.toLowerCase() === 'usdc'), [assets]);

    const feeToken = useMemo(
        () => assets?.find(asset => asset.symbol?.toLowerCase() === process.env.NEXT_PUBLIC_CUSTOM_FEE_ASSET),
        [assets],
    );

    const onSessionRequest = useCallback(
        (requestEvent: Web3WalletTypes.SessionRequest) => {
            enqueue({ request: requestEvent, status: 'initial' });
            setOpenRequest(true);
        },
        [enqueue],
    );

    const handleCloseSessionRequestModal = useCallback(() => {
        setOpenRequest(false);
        setQueue([]);
    }, [setQueue]);

    const handleReject = useCallback(
        async (requestItem: TSessionRequestQueue) => {
            try {
                const { topic, id } = requestItem.request;
                const rejectionResponse = {
                    id,
                    jsonrpc: '2.0',
                    error: { code: 5000, message: 'User rejected.' },
                };

                await web3wallet.respondSessionRequest({
                    topic,
                    response: rejectionResponse,
                });
                updateQueueItemStatus(id, 'rejected', '', rejectionResponse.error);
            } catch (error) {
                console.error('Error in handleReject:', error);
                // handle error appropriately
            }
        },
        [updateQueueItemStatus],
    );

    const handleApproveSessionRequest = useCallback(
        async (mode: string, requestItem: TSessionRequestQueue) => {
            const { id, topic, params } = requestItem.request;

            updateQueueItemStatus(id, 'processing');

            try {
                let response;
                const method = params.request.method;

                switch (method) {
                    case 'eth_signTypedData_v4':
                    case 'eth_signTypedData':
                        response = await handleEthSignTypedData(walletClient, smartAccount, requestItem.request);
                        break;
                    case 'personal_sign':
                        response = await handlePersonalSign(
                            walletClient,
                            smartAccount,
                            requestItem.request,
                            activeSessions,
                        );
                        break;
                    case 'eth_sendTransaction':
                        response = await handleSessionRequest(
                            requestItem.request,
                            smartAccount,
                            mode,
                            kernelAccount,
                            walletClient,
                            setTransactionHistory,
                            transactionConfigs,
                            feeToken?.address,
                            activeChain,
                        );

                        if (assets && balances) {
                            try {
                                setBalancesLoading(true);
                                const updatedBalances = await getBalances(assets, accountAddress, activeChain);

                                setBalances(updatedBalances);
                            } catch {
                                setBalancesLoading(false);
                            }
                        }
                        break;
                    default:
                        console.error('Unknown method:', method);
                        break;
                }

                if (!response) {
                    throw new Error('Error in handleApproveSessionRequest: response is empty');
                }

                await respondToWeb3(topic, response);
                updateQueueItemStatus(id, 'success', response.result);
            } catch (error: any) {
                console.error('Error in handleApproveSessionRequest:', error);

                let innerErr: any = error;

                try {
                    await respondToWeb3(topic, createErrorResponse(id, error.message, error));
                } catch (innerError) {
                    console.error('Error reporting error to web3:', innerError);

                    innerErr = innerError;
                } finally {
                    updateQueueItemStatus(id, 'rejected', '', {
                        code: '',
                        message: `An unexpected error occurred: ${innerErr?.message}`,
                    });
                }
            }
        },
        [
            updateQueueItemStatus,
            accountAddress,
            smartAccount,
            assets,
            balances,
            activeChain,
            setBalances,
            activeSessions,
            paymasterAddresses,
            usdcToken,
            feeToken,
            transactionConfigs,
        ],
    );

    return {
        openRequest,
        onSessionRequest,
        handleCloseSessionRequestModal,
        handleApproveSessionRequest,
        handleReject,
    };
};
