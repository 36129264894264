import { createSmartAccountClientWithMiddleware } from '@/helpers/Vault/createSmartAccountClientWithMiddleware';
import { TMintTransactionParams, TTransactionCommonParams } from '../types';

export const handleMintTapAppTxConfigs = async ({
    kernelAccount,
    txWithVoucher,
    smartAccount,
    activeChain,
    transactionModeConfigs,
}: TMintTransactionParams & TTransactionCommonParams) => {
    const call = {
        to: txWithVoucher.to,
        data: txWithVoucher.data,
        value: 0n,
    };

    let mintAccount = smartAccount;

    if (transactionModeConfigs?.base !== transactionModeConfigs?.daily_claim) {
        mintAccount = await createSmartAccountClientWithMiddleware({
            activeChain,
            kernelAccount,
        });
    }

    return {
        transactions: [call],
        transactionAccount: mintAccount,
        txSubType: 'mint_tap_app',
    };
};
