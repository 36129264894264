import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    XCircleIcon,
    XMarkIcon as XIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

export type AlertType = 'success' | 'error' | 'info' | 'warn';

export interface AlertProps {
    type: any;
    messageType: string | ReactNode;
    messageText: string | ReactNode;
    alertDisplayTime?: string;
    onClose?: () => void;
    onCloseByClick?: () => void;
    rootClassName?: string;
    alertIconBlockClassName?: string;
    alertIconClassName?: string;
    contentBlockClassName?: string;
    messageTypeClassName?: string;
    messageTextClassName?: string;
    closeIconBlockClassName?: string;
    closeIconClassName?: string;
    CustomIcon?: any;
    defaultSuccessColor?: string;
    defaultInfoColor?: string;
    defaultWarnColor?: string;
    defaultErrorColor?: string;
}

export const Alert: FC<AlertProps> = ({
    type,
    messageType,
    messageText,
    alertDisplayTime,
    onClose,
    onCloseByClick,
    rootClassName,
    alertIconBlockClassName,
    alertIconClassName,
    contentBlockClassName,
    messageTypeClassName,
    messageTextClassName,
    closeIconBlockClassName,
    closeIconClassName,
    CustomIcon,
    defaultSuccessColor,
    defaultInfoColor,
    defaultWarnColor,
    defaultErrorColor,
}: AlertProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleCloseAlert = useCallback(() => {
        onCloseByClick?.();
        onClose?.();
    }, [onCloseByClick, onClose]);

    useEffect(() => {
        if (alertDisplayTime) {
            const timer = setTimeout(() => {
                if (!isHovered) {
                    onClose?.();
                }
            }, +alertDisplayTime * 1000);

            return () => clearTimeout(timer);
        }
    }, [alertDisplayTime, isHovered, onClose]);

    const cxAlertIcon = useMemo(() => {
        if (alertIconClassName) {
            return alertIconClassName;
        }

        return classNames(
            'h-6',
            'w-6',
            type === 'success' && defaultSuccessColor,
            type === 'error' && defaultErrorColor,
            type === 'info' && defaultInfoColor,
            type === 'warn' && defaultWarnColor,
        );
    }, [alertIconClassName, type]);

    const renderAlertIcon = useMemo(() => {
        if (CustomIcon) {
            return <CustomIcon className={cxAlertIcon} />;
        }

        switch (type) {
            case 'success':
                return <CheckCircleIcon className={cxAlertIcon} />;
            case 'error':
                return <XCircleIcon className={cxAlertIcon} />;
            case 'info':
                return <InformationCircleIcon className={cxAlertIcon} />;
            case 'warn':
                return <ExclamationCircleIcon className={cxAlertIcon} />;
            default:
                return null;
        }
    }, [type, cxAlertIcon, CustomIcon]);

    const renderMessageText = useMemo(() => {
        if (typeof messageText === 'string') {
            return <p className={messageTextClassName}>{messageText}</p>;
        }

        return <div className={messageTextClassName}>{messageText}</div>;
    }, [messageText, messageTextClassName]);

    return (
        <div
            data-testid="root"
            className={rootClassName}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <div className={alertIconBlockClassName}>{renderAlertIcon}</div>
            <div className={contentBlockClassName}>
                <div className="flex items-center justify-between">
                    <p className={messageTypeClassName}>{messageType}</p>
                    <button onClick={handleCloseAlert} className={closeIconBlockClassName}>
                        <XIcon
                            className={classNames(
                                'h-4 w-4 flex-shrink-0 cursor-pointer text-text-color-60 hover:text-text-color-90',
                                closeIconClassName,
                            )}
                        />
                    </button>
                </div>
                {renderMessageText}
            </div>
        </div>
    );
};

Alert.defaultProps = {
    type: 'success',
    messageType: '',
    messageText: '',
    onClose: undefined,
    rootClassName:
        'flex items-start justify-between w-[300px] xs:w-[360px] h-max p-4 bg-white shadow-lg max-w-64 group',
    alertIconBlockClassName: 'flex-initial pr-3 h-full w-max',
    contentBlockClassName: 'flex-1 flex-col justify-start max-w-full',
    messageTypeClassName: 'text-sm font-medium pb-1 break-words',
    messageTextClassName: 'text-sm text-gray-500 break-words',
    closeIconBlockClassName: '',
    closeIconClassName: 'h-3.5 w-3.5 text-white cursor-pointer',
    defaultSuccessColor: 'text-green-500',
    defaultInfoColor: 'text-indigo-500',
    defaultWarnColor: 'text-yellow-500',
    defaultErrorColor: 'text-red-500',
};
