import { getWithdrawTransaction } from '@/helpers/Vault/getWithdrawTransaction';
import { TTransactionCommonParams, TWithdrawalTransactionParams } from '../types';
import { createSmartAccountClientWithMiddleware } from '@/helpers/Vault/createSmartAccountClientWithMiddleware';
import { Address } from 'viem';

export const handleWithdrawalTxConfigs = async ({
    addressTo,
    kernelAccount,
    smartAccount,
    tokens,
    activeChain,
}: TWithdrawalTransactionParams & TTransactionCommonParams) => {
    if (!smartAccount?.account?.address || !kernelAccount)
        throw new Error('Smart account or kernel account client not initialized');

    const withdrawalTransactions = getWithdrawTransaction(tokens, addressTo as Address, activeChain);

    let smartAccountClient = smartAccount;

    if (activeChain) {
        smartAccountClient = await createSmartAccountClientWithMiddleware({
            activeChain,
            kernelAccount,
        });
    }

    return {
        transactions: withdrawalTransactions,
        transactionAccount: smartAccountClient,
        txSubType: 'new_tx',
    };
};
