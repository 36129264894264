import { createSmartAccountClientWithMiddleware } from '@/helpers/Vault/createSmartAccountClientWithMiddleware';
import { TBaseTransactionParams, TTransactionCommonParams } from '../types';
import { TCall } from '@/helpers/Vault/types';

export const handleBaseTxConfigs = async ({
    kernelAccount,
    to,
    value,
    data,
    activeChain,
}: TBaseTransactionParams & TTransactionCommonParams) => {
    if (!activeChain) throw new Error('Chain not initialized');

    const transaction: TCall = {
        to,
        value,
        data,
    };

    const baseSmartAccount = await createSmartAccountClientWithMiddleware({
        activeChain,
        kernelAccount,
    });

    return {
        transactions: [transaction],
        transactionAccount: baseSmartAccount,
        txSubType: 'base',
    };
};
