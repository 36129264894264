import { TAsset } from '@/store/AssetsStore';
import { Address, Chain, encodeFunctionData } from 'viem';

export const getDepositTokenTransaction = (
    contractAddress: Address,
    token: TAsset,
    parsedAmount: bigint,
    activeChain: Chain | null,
) => {
    const depositData = encodeFunctionData({
        abi: [
            {
                type: 'function',
                name: 'deposit',
                inputs: [
                    {
                        name: 'token',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'amount',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
                outputs: [],
                stateMutability: 'payable',
            },
        ],
        args: [token.address as Address, parsedAmount],
    });

    const isNativeToken = token.symbol?.toLowerCase() === activeChain?.nativeCurrency?.symbol?.toLowerCase();

    return {
        to: contractAddress,
        value: isNativeToken ? parsedAmount : 0n,
        data: depositData,
    };
};
