import axios from 'axios';

import { useState, useCallback } from 'react';

export interface TransactionHistoryPayload {
    txHash: string;
    type: string;
    address: string;
    chainId: number;
    amount: number;
}

export const useSetTransactionHistory = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const setTransactionHistory = useCallback(async (payload: TransactionHistoryPayload) => {
        setLoading(true);
        try {
            const { txHash, type, chainId, amount } = payload;
            const { data } = await axios.put('/api/v2/private/user/history', {
                txid: txHash,
                type,
                chain_id: chainId,
                amount,
            });

            // Handle response data if needed
            return data; // Optionally return data if it will be used
        } catch (err) {
            setError(err as Error);
        } finally {
            setLoading(false);
        }
    }, []);

    return {
        setTransactionHistory,
        loading,
        error,
    };
};

export const putTransactionHistory = async (payload: TransactionHistoryPayload) => {
    try {
        const { txHash, type, chainId, amount } = payload;
        const { data } = await axios.put('/api/v2/private/user/history', {
            txid: txHash,
            type,
            chain_id: chainId,
            amount,
        });

        return data;
    } catch (err) {
        console.log('Error while setting transaction history', err);
    }
};
