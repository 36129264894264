import { proxy } from 'valtio';

export type TLockedAsset = {
    address: string;
    totalStakedAmount: string;
    chainId: number;
};

export interface ILockedAssetsState {
    lockedAssets: TLockedAsset[] | null;
}

const state = proxy<ILockedAssetsState>({
    lockedAssets: [] as TLockedAsset[] | null,
});

export const LockedAssetsStore = {
    state,
    setAssets(assets: TLockedAsset[] | null) {
        state.lockedAssets = assets;
    },

    reset() {
        state.lockedAssets = [];
    },
};
