import { useCallback, useEffect, useState } from 'react';
import { createWeb3Wallet } from '@/providers/walletConnect/helpers/createWeb3Wallet';

export default function useInitialization() {
    const [initialized, setInitialized] = useState(false);

    const onInitialize = useCallback(async () => {
        try {
            await createWeb3Wallet();
        } catch (err: unknown) {
            console.log('Error for initializing', err);
        }
    }, []);

    useEffect(() => {
        if (!initialized) {
            onInitialize();
            setInitialized(true);
        }
    }, [initialized, onInitialize]);

    return initialized;
}
