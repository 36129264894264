import { TAsset } from '@/store/AssetsStore';
import { TLockedAsset } from '@/store/LockedAssetsStore';
import { getUnlockAssetTransaction } from './getUnlockAssetTransaction';
import { Address, parseUnits } from 'viem';

export const getUnlockAssetsTransactions = (contractAddress: Address, assets: TAsset[], balances: TLockedAsset[]) => {
    return assets.flatMap((asset) => {
        const balance = balances.find((b) => b.address.toLowerCase() === asset.address.toLowerCase());

        if (!balance) return [];

        const parsedBalance = parseUnits(balance.totalStakedAmount, asset.decimals);

        if (parsedBalance === 0n) return [];

        const transaction = getUnlockAssetTransaction(contractAddress, asset.address as Address, parsedBalance);

        return [transaction];
    });
};
